/* ----------------------------------------
    Search filter
---------------------------------------- */

export class SearchResults {

    constructor() {
        this._filters = document.querySelectorAll('.search-results__filter');
        this._subsets = document.querySelectorAll('.search-results__subset');
        this._more = document.querySelectorAll('.search-results__more');

        if (!this._filters.length)
            return;

        this._init();
    }

    _filter(el) {
        for (let _filter of this._filters) {
            _filter.classList.remove('is-active');
        }

        el.classList.add('is-active');

        const subsetId = el.dataset.subset;

        for (let _subset of this._subsets) {
            const id = _subset.id;

            if (!subsetId || subsetId === id) {
                _subset.style.display = 'block';
            } else {
                _subset.style.display = 'none';
            }
        }
    }

    _showAll(el) {
        el.parentElement.classList.add('show-all');
    }

    _event() {
        for (let el of this._filters) {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                this._filter(el);
            });
        }

        for (let el of this._more) {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                this._showAll(el);
                
                el.remove();
            });
        }
    }

    _init() {
        this._event();
    }
}
