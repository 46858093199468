/** ----------------------------------------
    Connection Status
 ---------------------------------------- */

export class Status {

    constructor() {
        this.connection = true;
        this._listen();
    }

    /** ----------------------------------------
         Update Status
     ---------------------------------------- */

    get status() {
        return this.connection;
    }

    _update() {
        this.connection = navigator.onLine;
    }

    /** ----------------------------------------
         Listen Status
     ---------------------------------------- */

    _listen() {
        window.addEventListener('online', this._update, false);
        window.addEventListener('offline', this._update, false);
    }

}