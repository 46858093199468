/** ----------------------------------------
    Style Content
 ---------------------------------------- */

/**
 * This class provides template parts
 * that accept information as provided
 * from settings and/or the style.js file.
 */

import $ from 'jquery';

import { StyleConfig } from './StyleConfig.js';

export class StyleContent extends StyleConfig {

    /** ----------------------------------------
        Base Content
     ---------------------------------------- */

    appendToBody(html) {
        const container = document.querySelector('.style-container');
        $(container).append(html);
    }

    /** ----------------------------------------
         Style Block
     ---------------------------------------- */

    setStyleBlock(html, size = '', ...type){
        if (html == 'undefined' || html == '')
            return;

        if (!this.helper.empty(size)){
            size = `--${ size }`;
        }

        let block = `
             <div class="vv-style-block ${ type.join(' ') }">
                <div class="vv-style-block--center">
                    <div class="vv-style-block__inner${ size }">
                        ${ html }
                    </div>
                </div>
            </div>
        `;

        this.appendToBody(block);
    }

    /** ----------------------------------------
        Content Block
     ---------------------------------------- */

    getContentBlock(info, content){
        let div = 'div';
        let desc = '';

        if(info.article) {
            div = 'article';
        }

        if(info.desc) {
            desc = `<p class="vv-style-block__text">${ info.desc }</p>`;
        }

        let block = `
            <div class="vv-style-block__info">
                <h5 class="vv-style-block__title">${ info.title }</h5>
                ${ desc }
            </div>

            <${ div } class="vv-style-block__content">
                ${ content }
            </${ div }>
        `;

        return block;
    }

    /** ----------------------------------------
        Logo Content
     ---------------------------------------- */

    setLogo() {
        let html = `
            <div class="vv-logo">
                <img src="./assets/svg/vruchtvlees.svg">
            </div>
        `;

        this.setStyleBlock(html, 'large', 'vv-style-block--full', 'vv-style-block--combi');
    }

    /** ----------------------------------------
        Intro Content
     ---------------------------------------- */

    setIntro(info) {
        let html = `
            <h1 class="vv-style-block__top">${ info.title }</h1>
            <p>${ info.intro }</p>
        `;

        this.setStyleBlock(html, 'compact', 'vv-style-block__head');
    }

    /** ----------------------------------------
        Title Content
     ---------------------------------------- */

    setTitle(info){
        let desc = '';

        if(info.desc){
            desc = `<p>${ info.desc }</p>`
        }

        let html = `
            <h1 class="vv-style-block__top">${ info.title }</h1>
            ${ desc }
        `;

        this.setStyleBlock(html, 'compact', 'vv-style-block__head');
    }

    /** ----------------------------------------
        Seperator Content
     ---------------------------------------- */

    setSeparator(){
        this.appendToBody(`<hr>`);
    }

    /** ----------------------------------------
        Breakpoint Content
     ---------------------------------------- */

    setBreakpoints(info, data) {
        let collected = '';

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                collected += `
                    <div class="vv-breakpoint-card__row js-copy-template" data-copy="@include mq(${ key }) { }">
                        <p class="vv-breakpoint-card__title">${ key }</p>
                        <p class="vv-breakpoint-card__text">${ data[key] }</p>
                        <p class="vv-breakpoint-card__snippet">@include mq(${ key })</p>
                    </div>
                `;
            }
        };

        let html = `
            <div class="vv-breakpoint-card">
                ${ collected }
            </div>
        `;

        let content = this.getContentBlock(info, html);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Grid Content
     ---------------------------------------- */

    setGrid() {
        let content = `
            <div class="grid">${ this.setGridItem('massive', 1).join(' ') }</div>
            <div class="grid">${ this.setGridItem('large', 2).join(' ') }</div>
            <div class="grid">${ this.setGridItem('medium', 3).join(' ') }</div>
            <div class="grid">${ this.setGridItem('small', 4).join(' ') }</div>
            <div class="grid">${ this.setGridItem('tiny', 6).join(' ') }</div>
	    `;

        this.setStyleBlock(content, '', 'vv-style-block hidden:desktop:max');
    }

    setGridItem(size, times) {
        let content = [];

        for(let index = 0; index < times; index++){
            content.push(` 
                <div class="grid__item--${ size }">
                    <div class="vv-grid"></div>
                </div>
            `); 
        }

        content.unshift(`<div class="grid__item--${ size } vv-grid__snippet">.grid__item--${ size }</div>`);

        return content;
    }

    /** ----------------------------------------
        Font Face Content
     ---------------------------------------- */

    setFontFace(info, data) {
        let cards = this.getFontCard(data);
        let content = this.getContentBlock(info, cards);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Font Face Card
     ---------------------------------------- */

    getFontCard(data) {
        let collected = '';

        data.forEach(function(font) {
            collected += `
                <div class="vv-font-card" style="font-family: ${ font }">
                    <div class="vv-font-card__preview">Aa</div>
                    <div class="vv-font-card__info">
                        <p class="vv-font-card__snippet">font-family: ${ font };</p>
                    </div>
                </div>
            `;
        });

        return collected;
    }

    /** ----------------------------------------
        Letters Content
     ---------------------------------------- */

    setLetters(info, data) {
        let cards = this.getLetterCard(data);
        let content = this.getContentBlock(info, cards);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Letter Card
     ---------------------------------------- */

    getLetterCard(data) {
        let collected = '';

        data.forEach(function(font) {
            collected += `
                <div class="vv-letter-card" style="font-family: ${ font }">
                    <div class="vv-letter-card__thumb">
                        <div class="vv-letter-card__overlay">Aa</div>
                    </div>
                    <div class="vv-letter-card__preview">
                        <p class="vv-letter-card__snippet">font-family: ${ font };</p>
                        <div class="vv-letter-card__text">ABCDEFGHIJKLWNOPQRSTUVWXYZ</div>
                        <div class="vv-letter-card__text">abcdefghijklmnopqrstuvwxyz</div>
                        <div class="vv-letter-card__text">1234567890[{(,.;:?!$&*)}]</div>
                    </div>
                </div>
            `;
        });

        return collected;
    }

    /** ----------------------------------------
        Color Content
     ---------------------------------------- */

    setColors(info, data) {
        let cards = this.getColorCard(data);
        let content = this.getContentBlock(info, cards);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Color Card
     ---------------------------------------- */

    getColorCard(data) {
        let collected = '';

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                collected += `
                    <div class="vv-color-card js-copy-template" data-copy="color: color('${ key }');">
                        <div class="vv-color-card__preview" style="background: ${ data[key] }"></div>
                        <div class="vv-color-card__info">
                            <p class="vv-color-card__name">${ key }</p>
                            <p class="vv-color-card__value">${ data[key] }</p>
                            <p class="vv-color-card__value">${ this.helper.convertHexToRgba(data[key]) }</p>
                            <p class="vv-color-card__snippet">color('${ key }');</p>
                            <p class="vv-color-card__snippet">div.color:${ key }</p>
                        </div>
                    </div>
                `;
            }
        };

        return collected;
    }

    /** ----------------------------------------
        Color Combination Content
     ---------------------------------------- */

    setCombinations(info, data) {
        let cards = this.getCombinationCard(data);
        let content = this.getContentBlock(info, cards);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Color Combination Card
     ---------------------------------------- */

    getCombinationCard(data) {
        let collected = '';

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                collected += `
                    <div class="vv-color-card js-copy-template" data-copy="@include combi('${ key }');">
                        <div class="vv-color-card__preview" style="background: ${ data[key]['background'] }; color: ${ data[key]['color'] };">
                            <div class="vv-color-card__overlay">Text Example</div>
                        </div>
                        <div class="vv-color-card__info">
                            <p class="vv-color-card__name">${ key }</p>
                            <p class="vv-color-card__value">color: ${ data[key]['color'] }</p>
                            <p class="vv-color-card__value">background: ${ data[key]['background'] }</p>
                            <p class="vv-color-card__snippet">@include combi('${ key }');</p>
                            <p class="vv-color-card__snippet">div.combi:${ key }</p>
                        </div>
                    </div>
                `;
            }
        };

        return collected;
    }

    /** ----------------------------------------
        Color Branding Content
     ---------------------------------------- */

    setBranding(info, data) {
        let cards = this.getBrandingCard(data);
        let content = this.getContentBlock(info, cards);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Color Branding Card
     ---------------------------------------- */

    getBrandingCard(data) {
        let collected = '';

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let colors = '';
                let values = '';
                let length = Object.keys(data[key]).length;

                for (let value in data[key]) {
                    colors += `<div class="vv-color-card__preview--part" style="width: ${ (1/length*100).toFixed(2) }%; background: ${ data[key][value] }"></div>`;
                    values += `<p class="vv-color-card__value">${ value }: ${ data[key][value] }</p>`;
                }

                collected += `
                    <div class="vv-color-card js-copy-template" data-copy="color: branding(${ key }, default);">
                        <div class="vv-color-card__preview">
                            ${ colors }
                        </div>
                        <div class="vv-color-card__info">
                            <p class="vv-color-card__name">${ key }</p>
                            ${ values }
                            <p class="vv-color-card__snippet">branding(${ key }, default);</p>
                        </div>
                    </div>
                `;
            }
        };

        return collected;
    }

    /** ----------------------------------------
        Headings Content
     ---------------------------------------- */

    setHeadings(info) {
        let headings = [];
        let ids = [];

        for (let i = 1; i <= 6; i++) {
            let string = this.helper.randomString();

            headings.push(
                `<div class="vv-heading-card">
                    <h${ i } class="vv-heading-card__heading ${ string }">Heading H${ i }</h${ i }>
                </div>`
            );

            ids.push(string);
        }

        let html = headings.join('');
        let content = this.getContentBlock(info, html);

        let type = '';

        if(info.combi) {
            type = 'vv-style-block--lucky';
        }

        this.setStyleBlock(content, '', type);
        this.setFontSizeSnippet(headings.length, ids, 'vv-heading-card__snippet');
    }

    /** ----------------------------------------
        Text Content
     ---------------------------------------- */

    setText(info) {
        let string = this.helper.randomString();

        let text = `
            <div class="vv-text-card">
                <h4 class="vv-text-card__title">H4 Lorem ipsum consectetur adipisicing elit.</h4>
                <p class="vv-text-card__text ${ string }">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur autem consequatur facilis nam numquam optio quas quibusdam quidem reiciendis? Eum mollitia quasi rerum temporibus! Accusantium aliquam autem cum doloremque dolores doloribus earum expedita explicabo facilis harum id, itaque labore necessitatibus non quae quaerat quasi quisquam quod recusandae repellat repudiandae saepe similique unde! Adipisci, animi aspernatur cum debitis dolor error esse expedita harum minima nihil obcaecati odio quas quos sunt, tempora tempore, temporibus unde voluptas? Autem dolores fugiat quibusdam sunt?</p>
            </div>
        `;

        let type = '';

        if(info.combi) {
            type = 'vv-style-block--lucky';
        }

        let content = this.getContentBlock(info, text);
        this.setStyleBlock(content, '', type);
        this.setFontSizeSnippet(1, string, 'vv-text-card__snippet');
    }

    /** ----------------------------------------
        Article Content
     ---------------------------------------- */

    setArticle(info = {}) {
        let content = `
            <article class="vv-text-preview">
                <p class="vv-text-preview__snippet">H1</p>
                <h1>H1 Lorem ipsum dolor sit amet, consectetur adipisicing.</h1>
                <p class="vv-text-preview__snippet">BIG</p>
                <big>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque eos id laboriosam maxime modi nulla quibusdam quo similique tempore velit veniam, voluptatem. Consequatur cum excepturi facilis harum inventore? Cupiditate excepturi fugiat neque officiis ratione suscipit! Assumenda dolore dolorum enim, facere ipsam iure minus molestiae numquam possimus quaerat quia rem reprehenderit.</big></p>
                <p class="vv-text-preview__snippet">P</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, at consequatur cumque delectus dolore excepturi illum ipsa modi nisi nobis odit quia, quis saepe. Accusamus alias aliquam aliquid aspernatur culpa ea expedita itaque magni neque, odit quia ratione repellendus, repudiandae rerum sint soluta voluptates. Architecto ducimus itaque praesentium voluptates? Ad adipisci aliquid, aperiam architecto asperiores assumenda, at, consequuntur corporis cupiditate debitis dolorem dolorum ducimus ea eius excepturi impedit incidunt inventore ipsa laboriosam magnam maiores maxime minus molestiae molestias mollitia nesciunt nihil nobis non nostrum odio odit praesentium provident recusandae repellat sit vel voluptas! Corporis, dolorem facere officia officiis quam repudiandae?</p><p>
                <p class="vv-text-preview__snippet">UL</p>
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus!</li>
                </ul></p>
                <p class="vv-text-preview__snippet">B</p>
                <p><strong>Lorem ipsum dolor sit amet.</strong><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis eligendi error inventore maxime obcaecati ratione sapiente! Consectetur corporis dolorem eaque numquam placeat quia quod repudiandae voluptatibus. A accusantium adipisci animi aperiam atque consectetur corporis debitis deserunt, dignissimos dolorem doloremque eum ex, illo illum impedit iste labore maxime minima modi nisi nulla odio placeat possimus qui quisquam ratione rerum sed? Distinctio?</p>
                <p class="vv-text-preview__snippet">OL</p>
                <ol>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus!</li>
                </ol>
                <p class="vv-text-preview__snippet">SMALL</p>
                <p><small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci atque autem commodi cumque dignissimos distinctio doloribus enim esse exercitationem expedita illo in magnam magni nemo, nesciunt nisi praesentium quam quo reprehenderit sapiente sit tempora ut veniam voluptatum. Autem, nesciunt!</small></p>
            </article>
        `;

        let type = '';

        if(info.combi) {
            type = 'vv-style-block--lucky';
        }

        this.setStyleBlock(content, 'large', 'vv-style-block--contain', type);
    }

    /** ----------------------------------------
        Set Font Styles
     ---------------------------------------- */

    setFontStyles(){
        let data = this.data.font_style;

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let styles = [];
                let snippet = [];

                for (let style in data[key]) {
                    styles.push(style + ':');
                    styles.push(data[key][style] + ';');
                    snippet.push(`<p class="vv-text-card__snippet">${ style }: ${ data[key][style] }</p>`);
                }

                let cards = `
                    <div class="vv-text-card js-copy-template" style="${ styles.join(' ') }" data-copy="@include font(${ key });">
                        <p class="vv-text-card__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur autem consequatur facilis nam numquam optio quas quibusdam quidem reiciendis? Eum mollitia quasi rerum temporibus! Accusantium aliquam autem cum doloremque dolores doloribus earum expedita explicabo facilis harum id, itaque labore necessitatibus non quae quaerat quasi quisquam quod recusandae repellat repudiandae saepe similique unde! Adipisci, animi aspernatur cum debitis dolor error esse expedita harum minima nihil obcaecati odio quas quos sunt, tempora tempore, temporibus unde voluptas? Autem dolores fugiat quibusdam sunt?</p>
                    </div>
                    ${ snippet.join(' ') }
                `;

                let info = {
                    title: key.replace('_',' '),
                    desc: ''
                };

                let content = this.getContentBlock(info, cards);
                this.setStyleBlock(content);
            }
        }
    }

    /** ----------------------------------------
        Links Content
     ---------------------------------------- */

    setLinks(info) {
        let links = `
            <article class="vv-text-preview">
                <div class="vv-anchor-card">
                    <a href="http://www.vruchtvlees.com" class="vv-anchor-card__anchor">Check out this page!</a>
                    <p class="vv-anchor-card__snippet">default link</p>
                </div>
                <div class="vv-anchor-card">
                    <a href="http://www.vruchtvlees.com" target="blank" class="vv-anchor-card__anchor">Check out this website!</a>
                    <p class="vv-anchor-card__snippet">external link</p>
                </div>
                <div class="vv-anchor-card">
                    <a href="mailto:info@vruchtvlees.com" class="vv-anchor-card__anchor">Send me a mail!</a>
                    <p class="vv-anchor-card__snippet">mailto link</p>
                </div>
                <div class="vv-anchor-card">
                    <a href="tel:0707851734" class="vv-anchor-card__anchor">070-7851734</a>
                    <p class="vv-anchor-card__snippet">phone link</p>
                </div>
            </article>
        `;

        let content = this.getContentBlock(info, links);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
         Tooltip Content
     ---------------------------------------- */

    setToolTip(info) {
        let tooltips = `
            <article class="vv-text-preview">
                <div class="vv-anchor-card">
                    <div class="tooltip__holder">
                        More information
                        <div class="tooltip__content">Peek a boo!</div>
                    </div>
                    <p class="vv-anchor-card__snippet">default tooltip</p>
                </div>
                <div class="vv-anchor-card">
                    <span>Read along with me for 
                        <span class="tooltip__holder tooltip__holder--inline">
                            more information <div class="tooltip__content">Peek a boo!</div>
                        </span>
                    </span>
                    <p class="vv-anchor-card__snippet">inline tooltip</p>
                </div>
            </article>
        `;

        let content = this.getContentBlock(info, tooltips);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Form General Content
     ---------------------------------------- */

    setFormGeneral(info) {
        let form = `
            <div class="vv-text-card">
                <form class="form">
                    <div class="form__group">
                        <label>Firstname:</label>
                        <input type="text">
                    </div>
                    <div class="form__group">
                        <label>Lastname:</label>
                        <input type="text">
                    </div>
                    <div class="form__group">
                        <label>Email:</label>
                        <input type="email">
                    </div>
                    <div class="form__group">
                        <label>Message:</label>
                        <textarea></textarea>
                    </div>
                </form>
            </div>
        `;

        let content = this.getContentBlock(info, form);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Form Placeholder Content
     ---------------------------------------- */

    setFormPlaceholder(info) {
        let form = `
            <div class="vv-text-card">
                <form class="form">
                    <div class="form__group">
                        <input type="text" placeholder="Firstname">
                    </div>
                    <div class="form__group">
                        <input type="text" placeholder="Lastname">
                    </div>
                    <div class="form__group">
                        <input type="email" placeholder="Email">
                    </div>
                    <div class="form__group">
                        <textarea placeholder="Start typing..."></textarea>
                    </div>
                </form>
            </div>
        `;

        let content = this.getContentBlock(info, form);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Form Placeholder Content
     ---------------------------------------- */

    setFormOptions(info) {
        let form = `
            <div class="vv-text-card">
                <form class="form">
                    <div class="form__group">
                        <input type="checkbox" class="form__option" id="checkbox">
                        <label for="checkbox">Yes, subscribe to Vruchtvlees</label>
                    </div>
                    <div class="form__group">
                        <p class="form__desc">Select an option:</p>
                        <div class="form__group--chained">
                            <input type="radio" class="form__option" value="yes" name="radio" id="yes" checked="">
                            <label for="yes">Yes please!</label>
                        </div>
                        <div class="form__group--chained">
                            <input type="radio" class="form__option" value="no" name="radio" id="no">
                            <label for="no">No, thanks.</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <p class="form__desc">Select an option:</p>
                        <select>
                            <optgroup label="Vruchtvlees">
                                <option value="digital">Digital</option>
                                <option value="strategy">Strategy</option>
                                <option value="design">Design</option>
                            </optgroup>
                            <optgroup label="Founders">
                                <option value="michael">Michael Danker</option>
                                <option value="roman">Roman Stikkelorum</option>
                                <option value="rindor">Rindor Golverdingen</option>
                            </optgroup>
                        </select>
                    </div>
                </form>
            </div>
        `;

        let content = this.getContentBlock(info, form);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
     Form Placeholder Content
     ---------------------------------------- */

    setCustomFormOptions(info) {
        let form = `
            <div class="vv-text-card">
                <div class="dropdown is-hidden">
                    <div class="dropdown__label">Pick multiple</div>
                    <div class="dropdown__options">
                        <div class="dropdown__selected is-closed">Select founders <span class="drowndown__amount"></span></div>
                        <div class="dropdown__holder">
                            <form class="dropdown__form">
                                <div class="dropdown__option">
                                    <input type="checkbox" id="rindor-golverdingen">
                                    <label for="rindor-golverdingen">Rindor Golderdingen</label>
                                </div>
                                <div class="dropdown__option">
                                    <input type="checkbox" id="michael-danker">
                                    <label for="michael-danker">Michael Danker</label>
                                </div>
                                <div class="dropdown__option">
                                    <input type="checkbox" id="roman-stikkelorum">
                                    <label for="roman-stikkelorum">Roman Stikkelorum</label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
   
                <div class="dropdown is-hidden">
                    <div class="dropdown__label">Pick single</div>
                    <div class="dropdown__options">
                        <div class="dropdown__selected is-closed">Select developer</div>
                        <div class="dropdown__holder">
                            <form class="dropdown__form">
                                <div class="dropdown__option">
                                    <input type="radio" id="sander-hidding" name="developers">
                                    <label for="sander-hidding">Sander Hidding</label>
                                </div>
                                <div class="dropdown__option">
                                    <input type="radio" id="giovanni-marchese" name="developers">
                                    <label for="giovanni-marchese">Giovanni Marchese</label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        `;

        let content = this.getContentBlock(info, form);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        UL List Content
     ---------------------------------------- */

    setUlList(info) {
        let list = `
            <div class="vv-text-card">
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam, voluptatum.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae.</li>
                </ul>
            </div>
        `;

        let content = this.getContentBlock(info, list);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        OL List Content
     ---------------------------------------- */

    setOlList(info) {
        let list = `
            <div class="vv-text-card">
                <ol>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam, voluptatum.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae.</li>
                </ol>
            </div>

        `;

        let content = this.getContentBlock(info, list);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        DD List Content
     ---------------------------------------- */

    setDdList(info) {
        let list = `
            <div class="vv-text-card">
                <dl>
                    <dt>Work</dt>
                    <dd>Digital, Strategy & Design</dd>
                    <dt>Founders</dt>
                    <dd>Michael, Roman & Rindor</dd>
                </dl>
            </div>
        `;

        let content = this.getContentBlock(info, list);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Table Full Content
     ---------------------------------------- */

    setTableFull(info) {
        let table = `
            <div class="vv-text-card">
                <table>
                    <thead>
                        <tr>
                            <th>Vruchtvlees</th>
                            <th>People</th>
                            <th>Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Digital</td>
                            <td>Michael Danker</td>
                            <td>Digital Director</td>
                        </tr>
                        <tr>
                            <td>Design</td>
                            <td>Rindor Goverdingen</td>
                            <td>Creative Director</td>
                        </tr>
                        <tr>
                            <td>Strategy</td>
                            <td>Roman Stikkelorum</td>
                            <td>Strategic Director</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3">Address: Joseph Ledelstraat 98, 2518 KM Den Haag</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        `;

        let content = this.getContentBlock(info, table);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Table Plain Content
     ---------------------------------------- */

    setTablePlain(info) {
        let table = `
            <div class="vv-text-card">
                <table>
                    <tr>
                        <th>Vruchtvlees</th>
                        <th>People</th>
                        <th>Title</th>
                    </tr>
                    <tr>
                        <td>Digital</td>
                        <td>Michael Danker</td>
                        <td>Digital Director</td>
                    </tr>
                    <tr>
                        <td>Design</td>
                        <td>Rindor Goverdingen</td>
                        <td>Creative Director</td>
                    </tr>
                    <tr>
                        <td>Strategy</td>
                        <td>Roman Stikkelorum</td>
                        <td>Strategic Director</td>
                    </tr>
                    <tr>
                        <td colspan="3">Address: Joseph Ledelstraat 98, 2518 KM Den Haag</td>
                    </tr>
                </table>
            </div>
        `;

        let content = this.getContentBlock(info, table);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Button Color Content
     ---------------------------------------- */

    setButtonColors(info, data) {
        let _this = this;
        let names = this.data.buttons.names;

        names.forEach(function(button) {
            info.title = button.replace('-', ' ').replace('btn', 'button');
            let cards = _this.getButtonColorCard(data, button);
            let content = _this.getContentBlock(info, cards);
            _this.setStyleBlock(content);
        });
    }

    /** ----------------------------------------
        Button Color Card
     ---------------------------------------- */

    getButtonColorCard(data, name) {
        let collected = '';

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (['white', 'grey', 'cream'].includes(key))
                    break;

                collected += `
                    <div class="vv-button-card">
                        <span class="${ name }--${ key } js-copy-template" data-copy="${ name }--${ key }">Click me</span>
                        <p class="vv-button-card__snippet">.${ name }--${ key }</p>
                    </div>
                `;
            }
        };

        return collected;
    }

    /** ----------------------------------------
        Button Size Content
     ---------------------------------------- */

    setButtonSizes(info, data) {
        let _this = this;
        let sizes = this.data.buttons.size;
        let cards = _this.getButtonSizeCard(data, sizes);
        let content = _this.getContentBlock(info, cards);
        _this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Button Size Card
     ---------------------------------------- */

    getButtonSizeCard(data, sizes) {
        let btn_name = this.data.buttons.names[0];
        let btn_color = Object.keys(data)[0];
        let collected = '';

        sizes.forEach(function(size) {
            collected += `
                <div class="vv-button-card">
                    <span class="${ btn_name }--${ btn_color } btn--${ size.trim() } js-copy-template" data-copy="${ btn_name }--${ btn_color } btn--${ size.trim() }">Click me</span>
                    <p class="vv-button-card__snippet">.btn--${ size }</p>
                </div>
            `;
        });

        return collected;
    }

    /** ----------------------------------------
        Icons Content
     ---------------------------------------- */

    setIcons(info, data) {
        let _this = this;
        let collected = '';

        let colors = this.data.colors;
        let firstName = Object.keys(colors)[0];

        data.forEach(function(icon) {
            collected += info.color ? _this.getIconColorCard(icon, firstName) : _this.getIconCard(icon);
        });

        let content = this.getContentBlock(info, collected);
        this.setStyleBlock(content);
    }

    /** ----------------------------------------
        Icon Card
     ---------------------------------------- */

    getIconCard(name) {
        let html = `
            <div class="vv-icon-card js-copy-template" data-copy="icon__${ name.replace(' ','') }">
                <div class="vv-icon-card__thumb">
                    <span class="icon__${ name.replace(' ','') }"></span>
                </div>
                <div class="vv-icon-card__info">
                    <p class="vv-icon-card__snippet">.icon__${ name.replace(' ','') }</p>
                </div>
            </div>
        `;

        return html;
    }

    /** ----------------------------------------
        Icon Color Card
     ---------------------------------------- */

    getIconColorCard(name, color) {
        let html = `
            <div class="vv-icon-card js-copy-template" data-copy="icon__${ name.replace(' ','') }--${ color }">
                <div class="vv-icon-card__thumb">
                    <span class="icon__${ name.replace(' ','') }--${ color }"></span>
                </div>
                <div class="vv-icon-card__info">
                    <p class="vv-icon-card__snippet">.icon__${ name.replace(' ','') }--${ color }</p>
                </div>
            </div>
        `;

        return html;
    }

    /** ----------------------------------------
        Set Font Size Snippet
     ---------------------------------------- */

    setFontSizeSnippet(length, ids, snippet){
        for (let i = 0; i < length; i++) {
            $(`<p class="${ snippet }">
                font-size: ${$('.' + ids[i]).css('font-size')}
            </p>`).insertAfter($('.' + ids[i]));
        }
    }

}
