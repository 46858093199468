import $ from 'jquery';

/** ----------------------------------------
    Video
 ---------------------------------------- */

/**
 * This class will initiate the youtube selector
 */

export class Video {

    constructor(el) {
        this.el = el;
        this.thumbEl = this.el.querySelector('.video__thumb');
        this.imgEl = this.el.querySelector('.video__img');
        this.iframeEl = this.el.querySelector('.video__iframe');
        this.iframe = this.iframeEl.contentWindow;
        this.url = this.el.dataset.videoUrl;

        // Dont init video if no url is set
        if (!this.url) return;

        this.youtubeVideo = this.url.includes('youtube');
        this.vimeoVideo = this.url.includes('vimeo');
        this.videoObj = this.parseVideo();

        // init
        this.init();
    }

    parseVideo() {
        this.url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
            var type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            var type = 'vimeo';
        }

        return {
            type: type,
            id: RegExp.$6
        };
    }

    setYoutubeThumb() {
        this.imgEl.src = `https://img.youtube.com/vi/${this.videoObj.id}/maxresdefault.jpg`;
    }

    setVimeoThumb() {
        $.get('https://vimeo.com/api/v2/video/' + this.videoObj.id + '.json', (data) => {
            this.imgEl.src = data[0].thumbnail_large;
        });
    }

    setYoutubePlayEvent() {
        this.thumbEl.addEventListener('click', (e) => {
            e.stopPropagation();
            this.play();
        })
    }

    setVimeoPlayEvent() {
        this.thumbEl.addEventListener('click', (e) => {
            e.stopPropagation();
            this.play();
        })
    }

    play() {
        this.thumbEl.remove();

        if (this.videoObj.type === "youtube"){
            this.iframe.postMessage('{"event":"command","func":"playVideo","args":""}','*');
        }
        if (this.videoObj.type === "vimeo"){
            this.iframe.postMessage('{"method":"play"}','*');
        }
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        if (this.videoObj.type === "youtube"){
            this.setYoutubeThumb();
            this.setYoutubePlayEvent();
        }
        if (this.videoObj.type === "vimeo"){
            this.setVimeoThumb();
            this.setVimeoPlayEvent();
        }
    }
}