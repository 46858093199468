import helper from './Helper';
import template from './Templates';
import animation from './Animation';

/** ----------------------------------------
    Narrow Casting Tickets
 ---------------------------------------- */

export class Tickets {

    constructor() {
        this.refresh = 10000;
        this.refreshHighlights = 30000;
        this.scrollSpeed = 2000;
        this.introDuration = 10000;
        this.screen = {};
        this.current = 0;
        this.dataClone;
        this.ticketPerScreen = 4;
        this.initialized = false;
        this.screensaver = false;
        this.activeHighlight = 0;
        this.body = null;
        this.isDaytime = true;
    }

    /** ----------------------------------------
         Set Data
     ---------------------------------------- */

    set data(data) {
        this.screen = data;
        if (!this.dataClone) {
            this.dataClone = data.feed.slice(0);
        }
        if (!this.initialized) {
            this.init();
        }
    }

    /** ----------------------------------------
        Animate highlights
     ---------------------------------------- */

    _animateHighlights($this) {
        let highlight = document.querySelector('.screen__highlight');
        let highlightImg = document.querySelector('.screen__highlight-image');
        let highlightInfo = document.querySelector('.screen__highlight-info');
        animation.fadeIn(highlight, $this.scrollSpeed);
        // animation.zoomIn(highlightImg, $this.refreshHighlights, 0, 1.2);
        animation.fadeInUp(highlightInfo, $this.scrollSpeed);

        setTimeout(() => {
            animation.fadeOut(highlight, $this.scrollSpeed);
        }, $this.refreshHighlights - $this.scrollSpeed);
    }

    /** ----------------------------------------
        Cycle
     ---------------------------------------- */

     _cycle(data) {
        let $this = this;
        const introEl = document.body.querySelector('.screen-intro');
        let ticket = document.querySelector('.screen__event');
        let ticketHeight = 0;
        let marginTop = 0;
        let borderBottom = 1;

        if (ticket) {
            ticketHeight = ticket.offsetHeight + marginTop;
        }

        setInterval(function() {
            let screen = document.querySelector('.screen__slide');
            let firstTicket = document.querySelector('.screen__event');
            let allTickets = document.querySelectorAll('.screen__event');

            // if tickets dont fit on screen - cycle through
            if ($this.screen.feed.length > $this.ticketPerScreen) {
                animation.scrollStagger(allTickets, ticketHeight, $this.scrollSpeed);
                animation.fadeOut(firstTicket, $this.scrollSpeed);

                setTimeout(function() {
                    $this.dataClone.splice(0, 1);
                    $this.dataClone.push( $this.screen.feed[ $this.current ] )
                    $this._displayAll();
                    $this.current++;
                    if ($this.dataClone[$this.current] == 'undefined' || $this.dataClone[$this.current] == 'null') {
                        $this.current = 0;
                    }
                    if ($this.current >= $this.screen.feed.length) {
                        $this.current = 0;
                    }
                }, $this.scrollSpeed)
            // else just display movies and remove if necessary
            } else {
                // if movie has to be removed (already past the initial time)
                if (($this.dataClone.length > $this.screen.feed.length)) {
                    animation.scrollStagger(allTickets, ticketHeight, $this.scrollSpeed);
                    animation.fadeOut(firstTicket, $this.scrollSpeed);
                    setTimeout(function() {
                        $this.dataClone.splice(0, 1);
                        $this._displayAll();
                        $this.dataClone = $this.screen.feed;
                    }, $this.scrollSpeed);
                // else just watch for updates
                } else {
                    $this.dataClone = $this.screen.feed;
                    $this._displayAll();
                }

                // if no tickets, show screensaver screen
                if ($this.screen.feed.length <= 0 && $this.screen.highlightsFeed.length <= 0) {
                    if (!$this.screensaver) {
                        animation.fadeIn(introEl, 1000, $this.introDuration);
                        $this.screensaver = true;
                    }
                } else {
                    if ($this.screensaver) {
                        animation.fadeOut(introEl, 1000, $this.introDuration);
                        $this.screensaver = false;
                    }
                }
            }

        }, $this.refresh);

        this._animateHighlights($this)

        setInterval(() => {
            $this._displayHighlighted();
            this._animateHighlights($this);
        }, $this.refreshHighlights);

    }

    /** ----------------------------------------
        Display
     ---------------------------------------- */

    _displayAll () {
        let $this = this;

        let data = $this.dataClone;

        let children = ``;
        data.forEach(ticket => {
            children += template.ticket(ticket);
        });
        let parent = `<div class="screen__slide">${children}</div>`;
        helper.empty();
        helper.insert(parent);

        this._setTheme();
    }

    /** ----------------------------------------
        Display highlights
     ---------------------------------------- */

     _displayHighlighted () {
        let $this = this;
        let data = this.screen.highlightsFeed;

        let child;

        data.forEach((event, i) => {
            if (i == $this.activeHighlight) {
                child = template.highlight(event);
            }
        });

        this.activeHighlight ++;

        if (this.activeHighlight >= data.length){
            this.activeHighlight = 0;
        }

        let parent = `<div class="screen__highlight">${child}</div>`;

        helper.empty('highlights');
        helper.insert(parent, 'highlights');
    }

    /** ----------------------------------------
        Set dark or light theme
     ---------------------------------------- */

     _setTheme () {
        const hours = new Date().getHours();
        this.isDaytime = hours > 6 && hours < 18;
        this.isDaytime && this.body.classList.add('theme-light') && this.body.classList.remove('theme-dark');
        !this.isDaytime && this.body.classList.add('theme-dark') && this.body.classList.remove('theme-light');
    }

    /** ----------------------------------------
        Intro
     ---------------------------------------- */

    _intro() {
        let $this = this;
        const introEl = document.body.querySelector('.screen-intro');
        const logoEl = document.body.querySelector('.screen-intro__logo svg');
        const circles = document.body.querySelectorAll('.screen-intro__circle');
        const hours = new Date().getHours();
        const colors = ['purple','green','orange','pink'];
        const randColor = Math.floor(Math.random() * colors.length) + 0;
        const circleColored = document.body.querySelector('.screen-intro__circle--colored');
        const circleLast = document.body.querySelector('.screen-intro__circle--last');

        this.isDaytime = hours > 6 && hours < 18;
        this.body = document.querySelector('body');

        circleColored.classList.add(colors[randColor]);
        this.isDaytime ? circleLast.classList.add('beige') : circleLast.classList.add('black');

        animation.growStagger(circles, 5000, {w: this.body.offsetWidth, h: this.body.offsetHeight})
        animation.fadeOut(introEl, 1000, $this.introDuration);
        animation.zoomIn(logoEl, $this.introDuration * 1.5 , 0, 1.3);
        helper.initFadeIn();
        $this.initialized = true;
        helper.schedule($this.introDuration, function() {
            // INIT CYCLE
            $this._displayHighlighted();
            $this._displayAll();
            $this._cycle($this.dataClone);
        })
    }

    /** ----------------------------------------
        Initialize
     ---------------------------------------- */

    init() {
        this._intro();
        // refresh after 1 hr (only if connected)
        helper.schedule(3600000, function() {
            if (navigator.onLine) {
                location.reload();
            }
        })
    }
}
