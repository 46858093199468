/** ----------------------------------------
    Themes Toggle
 ---------------------------------------- */

/**
 * This class helps you toggle between
 * two themes. The function only works if
 * more than one theme has been declared
 * within the webpack configurations.
 */

export class ThemesToggle {

    /**
     * @settings        takes settings from webpack
     * @init            calls the init method
     */

    constructor(settings) {
        this.settings = settings;
        this.stylesheet = document.styleSheets[0].href;
        this.file = this.stylesheet.substring(this.stylesheet.lastIndexOf('/') + 1);
        this.fileName = this.file.substring(0, this.file.indexOf('.'));
        this.init();
    }

    /** ----------------------------------------
        Set Toggles
     ---------------------------------------- */

    /**
     * HTML templates for the toggles to be
     * added to the DOM.
     */

    setToggles() {
        let html = `
            <div class="theme-toggle"></div>  
        `;

        document.body.insertAdjacentHTML('beforeend', html);
    }

    setToggle(theme) {
        let html = `
            <div class="theme-toggle__button ${ theme === this.fileName && 'is-active' }" data-theme="${ theme }">
                ${ theme }
            </div>  
        `;

        let toggles = document.querySelector('.theme-toggle');

        toggles.insertAdjacentHTML('beforeend', html);
    }

    setThemes() {
        for(let theme of this.settings.themes) {
            this.setToggle(theme);
        }
    }

    /** ----------------------------------------
        Click Toggle
     ---------------------------------------- */

    /**
     * This methods is watching for a click event
     * on one of the toggles and changes the
     * stylesheet dynamically within the DOM.
     */

    clickToggle() {
        let _this = this;

        const toggles = document.querySelectorAll('[data-theme]');

        for (let toggle of toggles) {
            toggle.addEventListener('click', (e) => {
                toggle.classList.add('is-active');

                for (let i = 0; i < document.styleSheets.length; i++) {
                    document.styleSheets[i].disabled = true;
                }

                _this.createStylesheet(_this.stylesheet.replace(_this.fileName, toggle.dataset.theme));
            });
        }
    }

    /** ----------------------------------------
        Create Stylesheet
     ---------------------------------------- */

    /**
     * This function is will create a new
     * stylesheet within the DOM.
     */

    createStylesheet(href) {
        let link  = document.createElement('link');

        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = href;

        document.getElementsByTagName('head')[0].appendChild(link);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        if(this.settings.themes.length >= 2) {
            this.setToggles();
            this.setThemes();
            this.clickToggle();
        }
    }
}