/**
 * Vruchtvlees © 2007-2019
 *
 * Hi, welcome!
 *
 * This is the main Javscript file.
 * One script to rule them all!
 * Let's go and godspeed you!
 */

import $ from "jquery";
import jQuery from "jquery";

/** ----------------------------------------
    Webpack Settings
 ---------------------------------------- */

let webpack = SETTINGS;

/** ----------------------------------------
    Environment
 ---------------------------------------- */

/**
 * This class will check the current
 * environment of the website and stores
 * an object with information about the
 * current environment.
 */

import { Environment } from '@helper/Environment.js';
import { Log } from '@helper/Log.js';

const host = new Environment({
    localhost: 'vv-boilerplate',
    preview: 'vv-boilerplate',
    webpack: webpack
});

const log = new Log(host);

if(!host.info.environment.live) {
    log.section('VV', 'Environment');
    host.log();
}

function inView(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while(el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
}

/** ----------------------------------------
    Themes Toggle
 ---------------------------------------- */

/**
 * This section will set a toggle within
 * the DOM to switch between multiple
 * themes, the class only initiates if there
 * is more than one available theme declared
 * within the Webpack settings.
 */

import { ThemesToggle } from '@js/style/Theme.js';

new ThemesToggle(webpack);

/** ----------------------------------------
    Set Style Guide
 ---------------------------------------- */

/**
 * This section will set the VV Style Guide
 * as a default for at the index.html.
 *
 * You can mess around with the information
 * it will be displayed within the page.
 */

import { Style } from '@js/style/Style.js';

let info = {
    title: 'Vruchtvlees Digital Style Guide',
    intro: 'This style guide provides guidelines for the digital design as configured within the SASS stylesheets. It will automatically extend and change once you start working on the project. If certain elements or blocks should be left out these can be configured within the config.js file related to the style guide. It serves as a guide to quickly check what variables have been configured, what names they use and how the styling of a certain element has been affected within the stylesheet. Godspeed you!',
};

let config = {
    breakpoints: true,
    grid: true,
    typo: true,
    letters: true,
    colors: true,
    headings: true,
    text: true,
    content: true,
    font: true,
    links: true,
    tooltip: true,
    forms: true,
    lists: true,
    tables: true,
    buttons: true,
    icons: true
};

const style = new Style(info, config, webpack.config.production && webpack.config.guide && true);

if(!host.info.environment.live) {
    log.section('VV', 'Style');
    style.log();
}

/** ----------------------------------------
    Responsive Images
 ---------------------------------------- */

import { ResponsiveImages } from '@module/ResponsiveImages.js';

let responsive = new ResponsiveImages(style);

if(!host.info.environment.live) {
    log.section('VV', 'Responsive');
    responsive.log();
}

/** ----------------------------------------
    Dropdown
 ---------------------------------------- */

import { Dropdown } from '@module/Dropdown';

const filters = document.querySelectorAll('.dropdown__options');

for (let filter of filters) {
    if(filter) new Dropdown({
        holder: filter,
        trigger: '.dropdown__selected',
        target: '.dropdown__holder',
        options: '.dropdown__form'
    }, style, host);
}

/** ----------------------------------------
     Mobile Menu
 ---------------------------------------- */

import { MobileMenu } from "@js/module/MobileMenu";
new MobileMenu();

/** ----------------------------------------
     Search
 ---------------------------------------- */

import { Search } from "@js/module/Search";
new Search();

/** ----------------------------------------
     Search
 ---------------------------------------- */

 import { SearchResults } from "@js/module/SearchResults";
 new SearchResults();

/** ----------------------------------------
     Logo animation
 ---------------------------------------- */

const nav = document.querySelector('.nav');
const logo = document.querySelector('.nav__logo svg');
if(nav){
    const resizeLogo = () => {
        let thresh = 100;
        var top = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
        top > thresh && nav.classList.add('small-logo')
        top < thresh && nav.classList.remove('small-logo')
    }

    window.addEventListener('scroll', function(e) {
        resizeLogo();
    });


    logo && logo.addEventListener('load', function(e) {
        setTimeout(() => {
            resizeLogo();
        }, 400);
    });
}

/** ----------------------------------------
    Fill Stack
 ---------------------------------------- */

 const stackTitle = $('#overlay-annual [data-annual-title]')
 const stackSubtitle = $('#overlay-annual [data-annual-subtitle]')
 const stackIntro = $('#overlay-annual [data-annual-intro]')
 const stackBody = $('#overlay-annual [data-annual-body]')

 function fillStack(title, subtitle, intro, body){
    stackTitle.text(title);
    stackSubtitle.text(subtitle);
    stackIntro.html(intro);
    stackBody.html(body);
 }

/** ----------------------------------------
    Overlay
 ---------------------------------------- */

const overlay = (type) => {
    $(`.js-overlay-${type}-btn`).each(function() {
        $(this).click(function() {
            $(`.js-overlay-${type}`).addClass('active');
            if(type == 'annual'){
                let arSubtitle = $(this).closest('.annual-report__overlay-content').find('[data-annual-event-subtitle]').text();
                let arTitle = $(this).closest('.annual-report__overlay-content').find('[data-annual-event-title]').text();
                let arIntro = $(this).closest('.annual-report__overlay-content').find('[data-annual-event-intro]').html();
                let arBody = $(this).closest('.annual-report__overlay-content').find('[data-annual-event-body]').html();
                fillStack(arTitle, arSubtitle, arIntro, arBody);
            }
        })
    });

    $(`.js-overlay-${type}-close`).click(function() {
        $(`.js-overlay-${type}`).removeClass('active');
    })
}
overlay('annual');
overlay('large');
overlay('small');

/** ----------------------------------------
    Event Slider
---------------------------------------- */
import slick from 'slick-carousel';

window.addEventListener('load', () => {

    $('.js-event-slider').slick({
        infinite: true,
        slidesToShow: window.innerWidth > 960 ? 2 : 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        prevArrow: '<div class="slider__arrow slider__arrow--previous highlight-home__arrow--previous"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow--next highlight-home__arrow--next"></div>',
    });


    /** ----------------------------------------
     Gallery Block
     ---------------------------------------- */
    $('.js-gallery').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        prevArrow: '<div class="slider__arrow slider__arrow--previous  gallery-block__arrow--previous"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow--next gallery-block__arrow--next"></div>',
    });

    $('.js-gallery-auto').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        // dots: true,
    });

    /** ----------------------------------------
         Homepage Slider
     ---------------------------------------- */

    const headerSlider = () => {
        const slideDuration = 6500;
        const slider = $('.js-header-slider');
        const slideNo = slider.find('.header-item').length;

        $('.header-item').removeClass('is-hidden')

        if (slideNo <= 1) { return }

        slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            dots: false,
            draggable: false,
            fade: true,
            autoplay: false,
        });

        const animateTimer = () => {
            $('.js-slider-timer').addClass('animating');

            setTimeout(() => {
                $('.js-slider-timer').removeClass('animating');
                slider.slick('slickNext');
                // $('.js-slider-timer').addClass('full');

                // setTimeout(() => {
                //     // $('.js-slider-timer').removeClass('full');
                // }, 400);
            }, slideDuration);
        }

        animateTimer();

        $('.js-header-slider').on('beforeChange', function(event, slick, direction){
            animateTimer();
        });
    }

    headerSlider();
});

/** ----------------------------------------
    Fix Tile Heights, so all have the same height
 ---------------------------------------- */

 const fixTileHeights = (targetClass) => {
    let grids = document.querySelectorAll('.js-fix-tile-height');

    grids.forEach(grid => {
        let targets = grid.querySelectorAll(targetClass);
        let heights = [];
        targets.forEach(target => {
            heights.push(target.offsetHeight)
        })
        let maxHeight = Math.max(...heights)
        targets.forEach(target => {
            if (maxHeight != 0){
                target.style.height = `${maxHeight}px`;
            }
        })

    })
}

$( document ).ready(function() {
    fixTileHeights('.grid__item--small');
    fixTileHeights('.grid__item--medium');
    fixTileHeights('.grid__item--large');
});

/** ----------------------------------------
    Event overview tabs
 ---------------------------------------- */

const tabs = document.querySelectorAll('.js-tab');
const tabTargets = document.querySelectorAll('.js-tab-target');
const tabAll = document.querySelector('.js-tab-all');

const tabSwitch = () => {
    tabs.forEach(tab => {
        let activeTab = 0;

        tab.addEventListener('click', function () {
            let tabNo = tab.getAttribute('tab');
            activeTab = tabNo;

            tabTargets.forEach(target => {
                let targetNo =  target.getAttribute('tab');
                if (tabNo == targetNo) {
                    target.classList.remove('hidden-tab');
                    if (tabs[tabNo]) {
                        tabs[tabNo].classList.remove('hidden-tab');
                    }
                } else {
                    target.classList.add('hidden-tab');
                    if (tabs[tabNo]) {
                        tabs[tabNo].classList.add('hidden-tab');
                    }
                }
            });

            tabs.forEach(tab => { tab.classList.add('hidden-tab') });
            if (tabs[activeTab]) {
                tabs[activeTab].classList.remove('hidden-tab');
            }
            fixTileHeights('.grid__item--medium');

        }, false);
    });

    if (tabAll){
        tabAll.addEventListener('click', function () {
            $('.js-tab').each(function () {
                if ($(this).attr('tab') == 1) {
                    this.click();
                }
            });
        });
    }
};

tabSwitch();


/** ----------------------------------------
 Video
 ---------------------------------------- */

import { Video } from './module/video';

const videosElems = document.querySelectorAll('.video');
const videos = [];

for (let videoEl of videosElems) {
    videos.push(new Video(videoEl));
}

/** ----------------------------------------
 Accordion
 ---------------------------------------- */

$(document).ready(function(){
    $('.js-accordion').each(function() {
        $(this).find('.js-accordion__answer').hide();
        $(this).find('.js-accordion__question').each(function() {
            let question = $(this);
            let answer = $(this).parent().find('.js-accordion__answer');
            $(this).on('click', function() {
                answer.slideToggle('fast');
                if (question.hasClass('is-active')) {
                    question.removeClass('is-active');
                    answer.removeClass('is-active');
                } else {
                    question.addClass('is-active');
                    answer.addClass('is-active');
                }
            });

        });
    });
});

/** ----------------------------------------
    Event Slider
 ---------------------------------------- */
const eventSlider = document.querySelector('.event-overview-slider');

const setSliderMargins = () => {
    if (!eventSlider) { return }
    eventSlider.style.marginLeft = (window.innerWidth - 1140) / 2+"px";
    if (window.innerWidth < 1280) {eventSlider.style.marginLeft =  0 +"px"}
};
setSliderMargins();
window.onresize = function(event) {
    setSliderMargins();
};


/** ----------------------------------------
    Partners container
 ---------------------------------------- */

const partners = document.querySelector('.layout-2-col__bottom');

if (partners && partners.children.length < 1){
    partners.style.backgroundColor = 'unset';
    partners.style.margin = 0;
}

/** ----------------------------------------
    Narrowcasting
 ---------------------------------------- */

import { Narrowcasting } from "@js/module/narrowcasting/Narrowcasting";

const inNarrowcasting = document.querySelector('body').
                        classList.contains('narrowcasting');

inNarrowcasting && new Narrowcasting(host);


/** ----------------------------------------
     Event ticket relocate
 ---------------------------------------- */

$(document).ready(()=> {
    if (window.innerWidth <= 959) {
        $('.event-detail__tickets').css('margin-top', '1rem').insertAfter($('.event-detail__logline'));
    }
    window.addEventListener('resize', () => {
        if (window.innerWidth <= 959) {
            $('.event-detail__tickets').css('margin-top', '1rem').insertAfter($('.event-detail__logline'));
        } else {
            $('.event-detail__tickets').css('margin-top', '-112px').prependTo($('.layout-2-col__right'));
        }
    });
});

/** ----------------------------------------
    Newsletter
 ---------------------------------------- */

import { Newsletter } from "@js/module/Newsletter";

const newsletterEl = document.querySelector('#newsletter');

if (newsletterEl) {
    new Newsletter(newsletterEl);
}

/** ----------------------------------------
    Animate numbers
 ---------------------------------------- */

 import { CountUp } from 'countup.js';

 const countUpElements = document.querySelectorAll('.js-count-up');

 const initCountUp = (el) => {
     const number = parseFloat(el.getAttribute('number'));
     if (!number) return;

     const options = {
         decimalPlaces: 0,
         separator: '.',
         decimal: ',',
         duration: 1.4,
     };

     let demo = new CountUp(el, number, options);

     if (!demo.error) {
       demo.start();
     } else {
       console.error(demo.error);
     }
 }

 /** ----------------------------------------
    AOS - animate when on view
 ---------------------------------------- */


/** ----------------------------------------
    Tickets Cart
 ---------------------------------------- */

import { Tickets } from "@js/module/Tickets";

new Tickets();

/** ----------------------------------------
    Livestream
 ---------------------------------------- */

 function toggle(elem, toggleString) {
    const instructions = toggleString.split(',')
    elem.addEventListener('click', function(e) {
      instructions.forEach(string => {
        const instruction = string.split(":");
        const selector = instruction[0];
        const className = instruction[1];
        document.querySelectorAll(selector).forEach(elem => elem.classList.toggle(className));
      });
    })
  }

 (async function(nodeList) {
    if(!nodeList) return;
    nodeList.forEach(elem => {
      toggle(elem, elem.dataset.jsToggle);
    })
  })(document.querySelectorAll('[data-js-toggle]'))

const fs = document.querySelector('.fullscreen');
const fsImage = document.querySelector('.fullscreen__content--image');
const fsTriggers = document.querySelectorAll('.fullscreen--trigger');
const fsClose = document.querySelector('.fullscreen__close');

fsTriggers.forEach((fsTrigger) => {
    fsTrigger.addEventListener('click', () => {
        document.body.classList.add('no-scroll');
        fs.classList.add('open');
        setTimeout(function() {
            fs.classList.add('transition');
        }, 1);
        fsImage.setAttribute('src', fsTrigger.getAttribute('data-src'))
    });
});

if (fsClose) {
    fsClose.addEventListener('click', () => {
        document.body.classList.remove('no-scroll');
        fs.classList.remove('open', 'transition');
        fsImage.setAttribute('src', ' ');
    });
}
document.querySelectorAll('.number-scroller__wrapper').forEach(scroller => {
    let page2Items = scroller.querySelectorAll('.number-scroller__categories > h3');
    let optionsNS = {
    root: scroller, // Wat moet er scrollen
    rootMargin: '0px', // Tot waar ligt het scrollgebied?
    threshold: 0.34 // Hoe ver mag het element in beeld zijn? .5 is voor de helft over het scrollgebied, 1.0 element moet volledig in beeld zijn, 0 is element is nét niet in beeld
    }

    let callbackNS = (entries, observer) => {
    entries.forEach(entry => {
        const targetIndexNS = [...entry.target.parentNode.children].indexOf(entry.target);
        const page2Elem = page2Items[targetIndexNS];

        if(entry.isIntersecting) {
        page2Elem.classList.add('current'); // is in beeld
        } else {
        page2Elem.classList.remove('current');
        }
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
    });
    };

    let targetsNS = scroller.querySelectorAll('.number-scroller__amount > span'); // targets
    let observerNS = new IntersectionObserver(callbackNS, optionsNS);
    targetsNS.forEach(elem => {
        observerNS.observe(elem);
    })
 });


window.onscroll = function() {
    // chapters.forEach(chapter => {
    //     if(window.pageYOffset > offset(chapter).top) {
    //         let title = chapter.getAttribute('menutitle')
    //         barInner.innerHTML = title;
    //         bar.classList.add('is-active')
    //     }
    // })

    // if (window.pageYOffset < offset(chapters[0]).top) {
    //     bar.classList.remove('is-active')
    // }

    countUpElements.forEach(el => {
        if (inView(el)) {
            if (parseFloat(el.innerHTML) <= 0) {
                initCountUp(el)
            }
        }

    })
};

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

document.addEventListener("DOMContentLoaded", function() {

    setTimeout(() => {
        animateTransitions();

    },1500)


});

import AOS from 'aos';

const animateTransitions = () => {
    AOS.init({
        offset: 0,
        duration: 1000,
        delay: 0,
    });
}


window.addEventListener('load', function() {
    AOS.refresh();
});

/** ----------------------------------------
    Filter
 ---------------------------------------- */
 const filterForm = document.getElementById('filter-form');
if(filterForm) {
    const filterBtn = document.querySelector('.js-filter-btn');
    const filterOptions = document.querySelector('.js-filter-options');
    const filterCheckboxes= document.querySelectorAll('.js-filter-checkbox');
    const makers = document.querySelectorAll('.js-maker-tile');
    const filterPreview = document.querySelectorAll('.js-filter-preview');
    const checkboxes = document.querySelectorAll('.js-filter-checkbox');

    filterBtn.addEventListener('click', function() {
        filterOptions.classList.toggle('is-open');
    });

    let values = [];
    let tiles = [];


    function useFilter() {
        let values = {
            coproduction: false,
            residence: false,
            presentation: false,
        };
        let tags = [];

        const empty = [].filter.call( checkboxes, function( el ) {
            return !el.checked
         });

        if (checkboxes.length == empty.length) {
            makers.forEach((maker) => {
                maker.classList.remove("hidden");
            });
        } else {
            makers.forEach((maker) => {
                maker.classList.add("hidden");
            });
        }

        filterCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                tags.push(
                    document.querySelector(
                        ".js-filter-preview[data-" + checkbox.name + '="1"]'
                    )
                );
                values[checkbox.name] = true;
            }
        });


        filterPreview.forEach((filter) => {
            filter.classList.add("hidden");
        });

        let activeFilter;
        Object.entries(values).forEach(([key, value]) => {
            if (value) activeFilter = key;
        });

        const filteredMakers = [...makers].filter((maker) => {
            const coproduction = maker.dataset.coproduction;
            const residence = maker.dataset.residence;
            const presentation = maker.dataset.presentation;

            const makerValues = {
                coproduction: coproduction === "1" ? true : false,
                residence: residence === "1" ? true : false,
                presentation: presentation === "1" ? true : false,
            };

            const singleFilter = tags.length === 1;
            if (singleFilter && makerValues[activeFilter]) return true;

            if (
                makerValues.coproduction === values.coproduction &&
                makerValues.residence === values.residence &&
                makerValues.presentation === values.presentation
            ) {
                return true;
            }
        });

        tags.forEach((el) => {
            el.classList.remove("hidden");
        });

        filteredMakers.forEach((el) => {
            el.classList.remove("hidden");
        });

    }


    filterForm.addEventListener('change', function() {
        useFilter();
    });
    filterPreview.forEach(item => {
        item.addEventListener('click', function() {
            item.classList.add('hidden');
            document.querySelectorAll('.js-filter-checkbox[name='+ item.getAttribute('data') +']')[0].checked = false;
            useFilter();
        })
    })

}

