/** ----------------------------------------
     Node Modules
 ---------------------------------------- */

import axios from 'axios';

/** ----------------------------------------
    Custom Modules
 ---------------------------------------- */

import { Status } from './Status.js';
import { Tickets } from './Tickets.js';

/** ----------------------------------------
     Custom Helpers
 ---------------------------------------- */

import date from './Date.js';
import helper from './Helper';

/** ----------------------------------------
    Narrow Casting Screen
 ---------------------------------------- */

const tickets = new Tickets();

/** ----------------------------------------
    Narrow Casting Monitor
 ---------------------------------------- */

/**
 * This script will facilitate the
 * narrow casting functionality for the
 * screens.
 */

export class Narrowcasting {

    constructor(host) {
        this.data = {};
        this.date = new Date();
        this.host = host;
        this.screen = {};
        this.status = null;
        this.init();
    }

    /** ----------------------------------------
        Set Type
     ---------------------------------------- */

    _type() {
        this.type = document.body.dataset.type;
    }

    _location() {
        this.location = document.body.dataset.location;
    }

    _handleType() {
        const screen = {
            'tickets': tickets,
        };

        this.screen = screen[this.type];
    }

    _updateType() {
        this.screen.data = this.data;
    }

    /** ----------------------------------------
         Fetch Data
     ---------------------------------------- */

    _fetch() {
        axios.get(`/api/narrowcasting/${this.location}`).then(response => {
            this._settings(response.data);
            this._updateType();
        });
    }

    _fetchHighlights() {
        axios.get(`/api/narrowcasting/highlights`).then(response => {
            this._setOrder(response.data);
        });
    }

    _update() {
        this.connection = new Status();

        setInterval(() => {
            if (navigator.onLine) {
                this.connection.status && this._fetch();
                this.connection.status && this._fetchHighlights();
                console.log(`%c data update. `, 'font-family: monospace; background-color: rgb(0,0,255); color: white;');
            }

        }, 60000);

    }

    /** ----------------------------------------
        Set highlights order
     ---------------------------------------- */

    /**
     * Set a random order for higlights
     */

     _setOrder(data) {
        // only if data changes
        if (helper.arraysEqual(data, this.data['originalHighlights'])) return;

        this.data['highlightsFeed'] = helper.shuffleArr(data);
        this.data['originalHighlights'] = data;
     }

    /** ----------------------------------------
         Create Settings
     ---------------------------------------- */

    /**
     * This function sets several keys with
     * data than can be used within the screens
     */

    _settings(data) {
        this.data['feed'] = data;
        this._lengthData();
        this._currentData();
        this._prevData();
        this._nextData();
        this._handleData();
    }

    count() {
        this._settings(this.data['feed']);
    }

    /** ----------------------------------------
        Handle Data
     ---------------------------------------- */

    /**
     * This function will handle incrementing
     * several fields of data.
     */

    _handleData() {
        const increment = ['current', 'next', 'prev'];
        increment.map(type => this.data[type] += (this.data[type] < this.data.length && 1 || -this.data.length + 1));
    }

    /** ----------------------------------------
        Data Indexing
     ---------------------------------------- */

    _currentData(current = this.data.current) {
        this.data['current'] = current || 0;
    }

    _prevData(prev = this.data.prev) {
        this.data['prev'] = prev || this.data.current - 1;
    }

    _nextData(next = this.data.next) {
        this.data['next'] = next || this.data.current + 1;
    }

    /** ----------------------------------------
        Check orientation
     ---------------------------------------- */

    _checkOrientation() {
        const body = document.querySelector('.narrowcasting');
        window.innerHeight > window.innerWidth ? body.classList.add('narrowcasting--vertical') : body.classList.add('narrowcasting--horizontal');
    }

    /** ----------------------------------------
        Data Length
     ---------------------------------------- */

    /**
     * This function will check the amount of
     * events that are available from the feed.
     */

    _lengthData() {
        this.data['length'] = this.data.feed.length;
    }

    /** ----------------------------------------
         Time
     ---------------------------------------- */

    _setTime() {
        const el = document.querySelector('#js-time');
        const set = el.innerHTML = date.makeTime(this.date);
        setInterval(() => {
            this.date = new Date();
            el.innerHTML = date.makeTime(this.date);
        }, 15000);
    }

    /** ----------------------------------------
         Date
     ---------------------------------------- */

    _setDate() {
        const el = document.querySelector('#js-date');

        const day = this.date.getDay();
        const days = this.date.getDate();
        const month = this.date.getMonth();

        const set = el.innerHTML = `${ date.getDay(day) } ${ days } ${ date.getMonth(month) }`;

        setInterval(() => set, 60 * 60 * 1000);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        window.addEventListener('load', () => {
            this._checkOrientation();
            this._type();
            this._location();
            this._handleType();
            this._fetch();
            this._fetchHighlights();
            this._update();
            this._setTime();
            this._setDate();
        });
    }

}
