/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from "animejs";

/* ----------------------------------------
    Mobile Menu
---------------------------------------- */

export class MobileMenu {
    constructor() {
        this._mobile = document.querySelector(".mobile");

        if (!this._mobile) return;

        this._openMenu = document.querySelector(".js-open-menu");
        this._closeMenu = document.querySelector(".js-close-menu");

        this._mobileDropdowns = document.querySelectorAll('.js-mobile-dropdown');

        this._init();
    }

    _open() {
        anime({
            targets: this._mobile,
            opacity: [0, 1],
            scale: [1.1, 1],
            easing: "easeOutQuad",
            duration: 350,
            begin: () => {
                this._mobile.style.display = "block";
            },
        });
    }

    _close() {
        anime({
            targets: this._mobile,
            opacity: [1, 0],
            scale: [1, 1.1],
            easing: "easeOutQuad",
            duration: 350,
            complete: () => {
                this._mobile.style.display = "none";
            },
        });
    }

    _dropdown() {
        this._mobileDropdowns.forEach((dropdown) => {
            const toggle = dropdown.querySelector('.js-mobile-dropdown-toggle');
        
            toggle.addEventListener('click', () => {
                if (dropdown.classList.contains('active')) {
                    dropdown.classList.remove('active');
                } else {
                    dropdown.classList.add('active');
                }
            });
        });
    }

    _event() {
        this._openMenu.addEventListener("click", () => {
            this._open();
        });

        this._closeMenu.addEventListener("click", () => {
            this._close();
        });

        if (this._mobileDropdowns.length > 0) {
            this._dropdown();
        }
    }

    _init() {
        if (!this._openMenu) return;
        this._event();
    }
}
