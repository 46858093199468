/** ----------------------------------------
     Element Values
 ---------------------------------------- */

const container = document.body.querySelector('.screen__main');
const retainer = document.body.querySelector('.screen__retainer');
const active = document.body.querySelector('.screen__active');
const highlights = document.body.querySelector('.screen__highlights');

/** ----------------------------------------
    Show once ready
 ---------------------------------------- */

const initFadeIn = () => {
    document.getElementsByTagName("body")[0].style.opacity = 1; 
}

/** ----------------------------------------
    Select Element
 ---------------------------------------- */

const select = {
    'container': container,
    'retainer': retainer,
    'active': active,
    'highlights': highlights
};

/** ----------------------------------------
     Slide Index
 ---------------------------------------- */

const index = number => number - 1;

/** ----------------------------------------
     Apply Slide
 ---------------------------------------- */

const insert = (html, type = 'container') => {
    select[type].insertAdjacentHTML('beforeend', html);
};

/** ----------------------------------------
     Copy Container
 ---------------------------------------- */

const copy = (original = 'retainer', duplicate = 'active') => {
    select[duplicate].innerHTML = select[original].innerHTML;
};

/** ----------------------------------------
     Empty Container
 ---------------------------------------- */

const empty = (type = 'container') => select[type].innerHTML = '';

/** ----------------------------------------
     Image Loaded
 ---------------------------------------- */

const imageLoaded = url => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.addEventListener('load', e => resolve(img));
        img.addEventListener('error', () => {
            reject(new Error(`Failed to load image's URL: ${url}`));
        });
        img.src = url;
    });
};

/** ----------------------------------------
     Shuffle arr
 ---------------------------------------- */

const shuffleArr = (arr) => {
    let a = arr.slice(0)    
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

/** ----------------------------------------
     Arrays Equal
 ---------------------------------------- */

const arraysEqual = (a1,a2) => {
    return JSON.stringify(a1)==JSON.stringify(a2);
}

/** ----------------------------------------
     Schedule
 ---------------------------------------- */

const schedule = (duration, callback) => {
    setTimeout(callback, duration);
};
/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

module.exports = {
    arraysEqual,
    initFadeIn,
    copy,
    empty,
    index,
    imageLoaded,
    insert,
    schedule,
    shuffleArr
};
