/** ----------------------------------------
     Node Modules
 ---------------------------------------- */

import axios from 'axios';

export class Tickets {

    constructor() {
        this.cartElems = document.querySelectorAll('.js-cart');
        this.cartTotalElems = document.querySelectorAll('.js-cart-total');
        this.ticketsIframe = document.querySelector('.js-tickets-iframe');
        if (this.cartElems.length === 0 || this.cartTotalElems.length === 0 || !this.ticketsIframe) return;

        this.ticketsUrl = this.cartElems[0].dataset.ticketsUrl;
        this.cartId = this.cartElems[0].dataset.cartId;
        this.cartUrl = `https://api.voordemensen.nl/v1/debrakkegrond/cart/${ this.cartId }`;
        this.cartEventId = 0;

        this.init();
    }

    updateCartTotal(total) {
        for (const cartTotalEl of this.cartTotalElems) {
            cartTotalEl.innerHTML = total > 0 ? total : '';
        }

        for (const cartEl of this.cartElems) {
            cartEl.style.display = total > 0 ? '' : 'none';
        }
    }

    redirect(url) {
        window.location.href = url;
    }

    init() {
        for (const cartEl of this.cartElems) {
            cartEl.addEventListener('click', (event) => {
                event.preventDefault();
                this.redirect(`${this.ticketsUrl}/overview/${ this.cartEventId}`);
            });
        }

        window.addEventListener('message', (event) => {
            if (event.data.vdm_basketcounter)
                this.updateCartTotal(parseInt(event.data.vdm_basketcounter));

            if(event.data.vdm_closeoverlay && this.ticketsIframe)
                this.redirect(this.ticketsIframe.dataset.redirect);
        });

        axios.get(this.cartUrl).then((res) => {
            let total = 0;
            res.data.forEach(item => {
                if (item.event_id) {
                    total+= item.numberoftickets;
                    this.cartEventId = item.event_id;
                }
            });

            this.updateCartTotal(total);
        });
    }
}
