/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Mobile Menu
---------------------------------------- */

export class Search {

    constructor() {
        this._navSearch = document.querySelector('.js-search-overlay');
        this._openSearch = document.querySelectorAll('.js-open-search');
        this._closeSearch = document.querySelectorAll('.js-close-search');
        this._overlay = document.querySelector('.js-overlay-search');
        this._input = document.querySelector('.js-search-input');

        this._isOpen = false;

        if (!this._overlay) {
            return;
        }

        this._init();
    }

    _open() {
        this._isOpen = true;

        this._overlay.classList.add('active');
        this._openSearch.forEach(el => { el.classList.remove('active'); });
        this._closeSearch.forEach(el => { el.classList.add('active'); });
        this._input.focus();

        anime({
            targets: this._navSearch,
            translateY: ['-100%', 0],
            easing: 'easeOutQuad',
            duration: 350,
            begin: () => {
                this._navSearch.style.display = 'block';
            }
        });
    }

    _close() {
        this._isOpen = false;

        this._overlay.classList.remove('active');
        this._openSearch.forEach(el => { el.classList.add('active'); });
        this._closeSearch.forEach(el => { el.classList.remove('active'); });


        anime({
            targets: this._navSearch,
            translateY: [0, '-100%'],
            easing: 'easeOutQuad',
            duration: 350,
            complete: () => {
                this._navSearch.style.display = 'none';
            }
        });
    }

    _event() {
        for (let el of this._openSearch) {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                (this._isOpen) ? this._close() : this._open();
            });
        }

        for (let el of this._closeSearch) {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                this._close();
            });
        }

        this._overlay.addEventListener('click', (e) => {
            e.preventDefault();
            this._close()
        });
    }

    _init() {
        this._event();
    }
}
