/** ----------------------------------------
     Node Modules
 ---------------------------------------- */

import axios from 'axios';

/**
 * This script will facilitate the
 * narrow casting functionality for the
 * screens.
 */

export class Newsletter {

    constructor(el) {
        this.el = el;
        this.message = this.el.nextElementSibling;
        this.button = this.el.querySelector('button');
        this.emailInput = this.el.querySelector('[name="email"]');
        this.url = this.el.action;
        this.init();
    }

    /** ----------------------------------------
         Post Data
     ---------------------------------------- */

    post() {
        const data = {
            email: this.emailInput.value
        };

        axios.post('/ajax/newsletter', data).then(res => {
            this.button.disabled = false;
            this.message.innerHTML = res.data.message;

            if (res.data.success) {
                this.el.remove();
            }
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.el.addEventListener('submit', (e) => {
            e.preventDefault();

            if (this.button.disabled)
                return;

            this.button.disabled = true;

            this.post();
        });
    }
}