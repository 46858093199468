/** ----------------------------------------
     Digits Helper
 ---------------------------------------- */

const digits = (time) => {
    return time.toString().length > 1 ? time : '0' + time;
};

/** ----------------------------------------
    Make Time
 ---------------------------------------- */

const makeHours = (time) => {
    return digits(time.getHours());
};

const makeMinutes = (time) => {
    return digits(time.getMinutes());
};

const makeTime = (time) => {
    return `${ makeHours(time) }:${ makeMinutes(time) }`;
};

/** ----------------------------------------
    Days
 ---------------------------------------- */

const days = {
    nl: [
        'Zondag',
        'Maandag',
        'Dinsdag',
        'Woensdag',
        'Donderdag',
        'Vrijdag',
        'Zaterdag'
    ],
    eng: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
};

/** ----------------------------------------
    Get Day
 ---------------------------------------- */

const getDay = (day, lang = 'nl') => days[lang][day];

/** ----------------------------------------
    Months
 ---------------------------------------- */

const months = {
    nl: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December'
    ],
    eng: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
};


/** ----------------------------------------
    Get Months
 ---------------------------------------- */

const getMonth = (month, lang = 'nl') => months[lang][month];

/** ----------------------------------------
    Module Exports
 ---------------------------------------- */

module.exports = {
    days,
    getDay,
    months,
    getMonth,
    makeTime
};