/** ----------------------------------------
     Highlight Slide Template
 ---------------------------------------- */

 const highlight = data => {
    let disciplines = ``;
    let types = ``;

    data.disciplines.forEach(discipline => {
        disciplines += `<p class="screen__highlight-tag  screen__highlight-discipline">${ discipline }</p>`;
    })

    data.types.forEach(type => {
        types += `<p class="screen__highlight-tag  screen__highlight-type">${ type }</p>`;
    })

    const html = `
        <div class="screen__highlight-inner">
            <div class="screen__highlight-info">
                <p class="screen__highlight-data screen__highlight-date">${ data.human_date }</p>
                <h2 class="screen__highlight-data screen__highlight-title">${ data.artist }</h2>
                <p class="screen__highlight-data screen__highlight-artist">${ data.title }</p>
                <p class="screen__highlight-data screen__highlight-subtitle">${ data.subtitle }</p>
                <div class="screen__highlight-tags">
                    ${disciplines}
                    ${types}
                </div>
            </div>
            <div class="screen__highlight-image-outer">
                <div class="screen__highlight-gradient"></div>
                <img class="screen__highlight-image" src="${data.image}">
            </div>
        </div>`;
    return html;
};

/** ----------------------------------------
     Ticket Row Template
 ---------------------------------------- */

 const ticket = data => {
    let disciplines = ``;
    let types = ``;

    data.disciplines.forEach(discipline => {
        disciplines += `<p class="screen__event-tag screen__discipline">${ discipline }</p>`;
    })

    data.types.forEach(type => {
        types += `<p class="screen__event-tag screen__type">${ type }</p>`;
    })

    const html = `<div class="screen__event">
            <p class="screen__event-data screen__event-date">${ data.human_date }</p>
            <h2 class="screen__event-data screen__event-title">${ data.artist }</h2>
            <p class="screen__event-data screen__event-artist">${ data.title }</p>
            <div class="screen__event-tags">
                ${disciplines}
                ${types}
            </div>
        </div>`;
    return html;
};

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

module.exports = {
    ticket,
    highlight
};
