/** ----------------------------------------
 Node Modules
 ---------------------------------------- */

const anime = require('animejs');

/** ----------------------------------------
 Fade
 ---------------------------------------- */

const fadeIn = (el, duration = 750, delay = 0) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [0, 1],
            duration: duration,
            delay: delay,
            easing: 'easeInQuart',
            complete: () => resolve()  
        });
    });
};

const fadeOut = (el, duration = 750, delay = 0) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [1, 0],
            duration: duration,
            delay: delay,
            easing: 'easeInQuart',
            complete: () => resolve()
        });
    });
};

const zoomIn = (el, duration = 750, delay = 0, scaleTo = 1.1) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            scale: [1, scaleTo],
            duration: duration, 
            delay: delay,
            easing: 'linear',
            complete: () => resolve()
        });
    });
}

const zoomOut = (el, duration = 750, delay = 0, scaleTo = 0.9) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            scale: [1, scaleTo],
            duration: duration, 
            delay: delay,
            easing: 'linear',
            complete: () => resolve()
        });
    });
}

const fadeInUp = (el, duration = 1400, delay = 0, startY = 100) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            translateY: [startY, 0],
            opacity: [0, 1],
            duration: duration,
            delay: delay,
            easing: 'linear',
            complete: () => resolve()
        });
    });
}

const fadeOutDown = (el, duration = 1400, delay = 0, endY = 50) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [1, 0],
            scale: [1, 1.02],
            duration: duration,
            delay: delay,
            easing: 'easeInOutQuad',
            complete: () => resolve()
        });
    });
}

const scrollStagger = (el, ticketHeight, duration = 1000) => {
    return new Promise((resolve) => {
        el.forEach((element, i) => {
            let stagger = 40;
            let delay = stagger * i;
            anime({
                targets: element,
                translateY: -ticketHeight,
                easing: 'easeInOutCubic',
                duration: duration - delay,
                delay: delay
            });
        });
    });
}

const growStagger = (el, duration = 200000, screenDims) => {
    // pythagoras. w of circle based on w of rectangle
    let w = Math.sqrt(Math.pow(screenDims.w, 2) + Math.pow(screenDims.h, 2))

    return new Promise((resolve) => {
        el.forEach((element, i) => {
            let stagger = 4000;
            let delay = stagger * i;
            anime({
                targets: element,
                width: [0,w],
                height: [0,w],
                easing: 'easeOutCubic',
                duration: duration,
                delay: delay
            });
        });
    });
}

// currently not used
const scrollToAnim = (el, scrollToY, duration = 1400) => {

    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    var start = el.scrollTop,
        change = scrollToY - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        el.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

/** ----------------------------------------
 Export Modules
 ---------------------------------------- */

module.exports = {
    fadeIn,
    fadeOut,
    zoomIn,
    zoomOut,
    fadeInUp,
    fadeOutDown,
    scrollStagger,
    scrollToAnim,
    growStagger
};