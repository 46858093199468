/** ----------------------------------------
    Responsive Images
 ---------------------------------------- */

/**
 * This class will select optimized images
 * for responsive views.
 */

export class ResponsiveImages {

    /**
     * @images          stores the active images
     * @breakpoints     available breakpoints
     * @point           current breakpoint
     * @selected        selected image breakpoints
     * @init            init class
     */

    constructor(style) {
        this.images = [];
        this.breakpoints = style.data.breakpoints;
        this.point = '';
        this.selected = [];
        window.onload = this.init();
    }

    /** ----------------------------------------
        Helpers
     ---------------------------------------- */

    getMin() {
        let array = Object.values(this.breakpoints);
        return Math.min(...array);
    }

    getMax() {
        let array = Object.values(this.breakpoints);
        return Math.max(...array);
    }

    getKey(object, value) {
        return Object.keys(object)
            .find(key => object[key] === value);
    }

    /** ----------------------------------------
        Check Position
     ---------------------------------------- */

    checkPosition(value) {
        if(this.breakpoints[value] == this.getMin() || this.breakpoints[value] == this.getMax())
            return false;
    }

    /** ----------------------------------------
        Select Images
     ---------------------------------------- */

    filterRange(points) {
        let rangeArray = [];

        for (let point of points) {
            let currentPoint = points.indexOf(point),
                nextPoint = points[currentPoint + 1],
                currentValue = this.breakpoints[points[currentPoint]],
                nextValue = this.breakpoints[nextPoint];

            if (currentPoint === points.length - 1)
                nextValue = 0;

            let range = {
                point: point,
                range: [currentValue, nextValue].sort((a, b) => {
                    return a - b
                })
            };

            rangeArray.push(range);
        }

        return rangeArray;
    }

    selectImages() {
        this.images = document.querySelectorAll('img[data-images]');

        for(let image of this.images) {
            let sizes = image.dataset.images;

            let obj = JSON.parse(sizes);
            let devices = Object.keys(obj);

            for (let device of devices) {
                if(!this.selected.includes(device))
                    this.selected.push(device);
            }

            if (obj[this.point] != null)
                this.setImage(image, obj[this.point]);

            if (obj[this.point] == null || obj[this.point] == 'undefined' && window.innerWidth < this.breakpoints[this.selected[0]]) {
                let filters = this.filterRange(this.selected);

                for (let filter of filters) {
                    let breakpoint = this.breakpoints[this.point];

                    if(breakpoint > filter.range[0] && breakpoint < filter.range[1]) {
                        this.setImage(image, obj[filter.point]);
                    }
                }
            } else {
                this.setDefault();
            }
        }
    }

    setImage(image, src) {
        image.src = src;
    }

    /** ----------------------------------------
        Clean Breakpoints
     ---------------------------------------- */

    setBreakpoints() {
        let _this = this;

        Object.keys(_this.breakpoints).map((key, index) => {
            _this.breakpoints[key] = parseInt(_this.breakpoints[key], 10);
        });
    }

    /** ----------------------------------------
        Check Initial Breakpoint
     ---------------------------------------- */

    checkPoint() {
        let width = window.innerWidth;
        this.point = Object.keys(this.breakpoints)
            .find(key => this.breakpoints[key] >= width);

        if(this.point == null && window.innerWidth < this.getMin())
            this.point = this.getKey(this.getMax());

        if(this.point == null && window.innerWidth > this.getMax())
            this.point = this.getKey(this.getMax());
    }

    checkRetina() {
        if (window.matchMedia) {
            let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
            return (mq && mq.matches || (window.devicePixelRatio > 1));
        }
    }

    /** ----------------------------------------
        Set Default
     ---------------------------------------- */

    setDefault() {
        if(!this.selected.includes(this.point) && this.selected.includes('default')){
            for(let image of this.images) {
                if(image.src.match(/\.(jpeg|jpg|gif|png)$/) == null) {
                    let sizes = image.dataset.images;
                    let obj = JSON.parse(sizes);

                    this.setImage(image, obj['default']);
                }
            }
        }
    }

    /** ----------------------------------------
        Log Results
     ---------------------------------------- */

    log() {
        let log = {
            retina: this.checkRetina(),
            current: this.point,
            breakpoints: this.breakpoints
        };

        console.log(log);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        this.setBreakpoints();
        this.checkPoint();
        this.selectImages();
        this.setDefault();
    }
}